<template>
  <el-menu :default-active="activePath" class="el-menu-vertical-demo"
           :collapse="isCollapse" id="el-menu">
    <el-menu>
      <img style="height: 58px"
           :src="isCollapse ? require('../assets/img/pic1.jpg'):require('../assets/img/piclast.png')" alt="">
    </el-menu>
    <router-link :to="item.path" v-for="item in menu_Item" :key="item.path">
      <el-menu-item :index="item.path">
        <i :class="'el-icon-'+item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
    </router-link>
    <el-submenu v-for="item in menu_Submenu" :index="item.path" :key="item.path">
      <template slot="title">
        <i :class="'el-icon-'+item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="item in item.children" :key="item.path">
        <router-link :to="item.path">
          <el-menu-item :index="item.path">{{ item.label }}</el-menu-item>
        </router-link>
      </el-menu-item-group>
    </el-submenu>
    <!--    <el-menu-item>-->
    <!--      <i class="el-icon-help"></i>-->
    <!--      <span slot="title">帮助</span>-->
    <!--    </el-menu-item>-->
  </el-menu>
</template>

<script>
import {queryGroup} from '../../api/requests.js'

export default {
  data() {
    return {
      activePath: '',
      menu: [
        {
          name: 'Home',
          label: '首页',
          icon: 's-home',
          path: '/',
        },
        {
          name: 'Statistics',
          label: '数据统计',
          icon: 's-order',
          path: '/statistics',
        },
        {
          name: 'EventCenter',
          label: '事件管理',
          icon: 'video-play',
          path: '/eventcenter',
        },
        {
          name: 'Help',
          label: '帮助',
          icon: 'help',
          path: '/help',
        },
        // {
        //   name: 'Other',
        //   label: '其他',
        //   icon: 'location',
        //   path: '/other',
        //   children: [
        //     {
        //       name: 'Page1',
        //       label: '页面1',
        //       icon: 'setting',
        //       path: '/page1',
        //     },
        //     {
        //       name: 'Page2',
        //       label: '页面2',
        //       icon: 'setting',
        //       path: '/page2',
        //     },
        //   ]
        // },
      ],
    };
  },
  created() {
    // 把当前Path值取出来
    this.activePath = this.$route.path
  },
  methods: {
    resetNvaMenu() {
      let user = localStorage.getItem('Authorization');
      queryGroup({'info': user}).then(res => {
        if (res.data.info.factoryName) {
          res.data.info.factoryName.forEach(res => {
            let menuItem = {
              name: 'EventCenter',
              label: '兴瑞',
              icon: 'setting',
              path: '/eventcenter',
            }
            menuItem.label = res
            menuItem.path = menuItem.path + "?factory=" + res
            // this.menu[1].children.push(menuItem)
            // if (menuItem.path.includes(this.$route.path)) {
            //   this.activePath = menuItem.path
            // }
          })
        }
      })
    }
  },
  mounted() {
    this.resetNvaMenu()
  },
  computed: {
    menu_Item() {
      return this.menu.filter(item => !item.children)
    },
    menu_Submenu() {
      return this.menu.filter(item => item.children)
    },
    isCollapse() {
      return this.$store.state.Tab.isCollapse
    }
  },
  watch: {
    $route() {
      //监听router变化 选中aside-item
      this.activePath = this.$route.path
    },
  },
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 208px;
  min-height: 100Vh;
}

#el-menu {
  height: 100vh;
  overflow: hidden;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>
