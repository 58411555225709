<template>
  <el-container>
    <el-aside width="auto">
      <CommonAside></CommonAside>
    </el-aside>
    <el-container>
      <el-header>
        <CommonHeader></CommonHeader>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <CommonFooter></CommonFooter>
      </el-footer>
    </el-container>
  </el-container>
</template>
<script>
import CommonAside from "./CommonAside.vue";
import CommonHeader from "./CommonHeader.vue";
import CommonFooter from "./CommonFooter.vue";

export default {
  name: "Main",
  components: {
    CommonAside,
    CommonHeader,
    CommonFooter,
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

.el-aside {
  height: 100vh;
}
</style>